import React from "react"
import styled from "styled-components"
// import { Link } from "gatsby"
// eslint-disable-next-line
import { Container, horizontalWhiteGreyAfterBG, SectionHeading, Para } from "../../styles"

export default function SectionLead() {
	return (
		<StyledContainer background={horizontalWhiteGreyAfterBG} modifiers={["section", "full_width"]}>
			<SectionHeading modifiers={["center"]}>Improve Your Reach</SectionHeading>
			<Para modifiers={["center", "narrow"]}>
				We interact with websites, apps, and software daily to make our life a richer, simpler experience. Why not build
				your own?
				{/* Checkout the <Link to="/blog">blog</Link> for articles, tutorials & tips to see what software, gear &
				resources I use to stay on the cutting edge while building software & websites. */}
			</Para>
		</StyledContainer>
	)
}
const StyledContainer = styled(Container)`
	position: relative;

	h2 {
		font-size: 2rem;

		@media only screen and (min-width: 800px) {
			font-size: 2.5rem;
		}
	}
`
