import React from "react"
import Layout_main from "../layouts/Layout_main.js"
import HomeHeader from "../components/home/HomeHeader.js"
import LogoCarousel from "../components/home/LogoCarousel.js"
import Lead from "../components/home/Lead.js"
import TwoColBlocks from "../components/home/TwoColBlocks.js"
import CTA from "../components/contact/CTA.js"

export default function HomePage({ location }) {
	return (
		<Layout_main location={location}>
			<HomeHeader />
			<LogoCarousel />
			<Lead />
			<TwoColBlocks />
			<CTA />
		</Layout_main>
	)
}
