import React from "react"
import styled from "styled-components"
import { Icon } from "../../lib"
import { isBrowser } from "../../utils"
import { Container, theme } from "../../styles"

export default function LogoCarousel() {
	return (
		<OuterContainer background={theme.grey} modifiers={["full_width", "nested"]}>
			{isBrowser && (
				<ContainerStyled as="div" modifiers={["noPadTop", "noPadBottom"]}>
					<div className="marquee">
						<div className="icon-wrapper">
							<Icon name="wordpress" width={76} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="react" width={76} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="apollo" width={76} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="graphql" width={76} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="node" width={76} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="javascript" width={76} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="gatsby" width={76} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="next" width={120} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="figma" width={76} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="xd" width={76} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="css" width={76} height={76} />
						</div>
						<div className="icon-wrapper">
							<Icon name="html" width={76} height={76} />
						</div>
					</div>
				</ContainerStyled>
			)}
		</OuterContainer>
	)
}

const OuterContainer = styled(Container)`
	position: relative;
	padding-top: 115px;
`

const ContainerStyled = styled(Container)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	padding-top: 22px;
	padding-bottom: 22px;

	.marquee {
		display: flex;
		justify-content: center;
		align-items: center;
		animation: marquee 320s linear infinite;
		animation-delay: -100s;
	}

	.icon-wrapper {
		margin-left: 92px;
	}

	svg {
		display: block;
		margin: 0 auto;
	}

	@keyframes marquee {
		0% {
			transform: translateX(150%);
		}
		100% {
			transform: translateX(-150%);
		}
	}
`
