import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
// eslint-disable-next-line
import { elevation, Container, SectionHeading, TinyStrongHeading, Para, horizontalWhiteGrey } from "../../styles"

export default function TwoColBlock() {
	return (
		<StaticQuery
			query={graphql`
				query WEB_DEV_DES_QUERY {
					developer: file(relativePath: { regex: "/photos/developer.jpg/" }) {
						childImageSharp {
							gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
						}
					}
					designer: file(relativePath: { regex: "/photos/designer.jpg/" }) {
						childImageSharp {
							gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
						}
					}
				}
			`}
			render={data => <WebDevDesTwoCol data={data} />}
		/>
	)
}

function WebDevDesTwoCol(props) {
	const { data } = props

	return (
		<Container modifiers={["section", "full_width"]} background={horizontalWhiteGrey}>
			<TwoColumnRow>
				<ImgWrapper>
					<StyledImg
						image={data.developer.childImageSharp.gatsbyImageData}
						alt="desktop computer, keyboard, laptop, and ipad on a wooden desk"
					/>
				</ImgWrapper>
				<div className="copy_wrapper">
					<TinyStrongHeading>Web Developer</TinyStrongHeading>
					<SectionHeading as="p">Building Outstanding User Interfaces</SectionHeading>
					<Para>
						The internet is constantly growing and currently our main source of communication, entertainment, and buying
						& selling our goods and services.
					</Para>
				</div>
			</TwoColumnRow>
			<TwoColumnRow reverse>
				<ImgWrapper>
					<StyledImg image={data.designer.childImageSharp.gatsbyImageData} alt="website design samples" />
				</ImgWrapper>
				<div className="copy_wrapper">
					<TinyStrongHeading>Web Designer</TinyStrongHeading>
					<SectionHeading as="p">Fascinating Ideas Executed Flawlessly</SectionHeading>
					<Para>
						Simplicity & clarity in communicating ideas. Provide engaging, visually stunning content. Discover your
						vision and turn ideas & sketches, into reality.
					</Para>
				</div>
			</TwoColumnRow>
		</Container>
	)
}

const TwoColumnRow = styled(Container)`
	@media only screen and (max-width: 1200px) {
		padding-left: 0;
		padding-right: 0;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;

	@media only screen and (min-width: 800px) {
		flex-direction: row;
		justify-content: center;
	}

	&:nth-of-type(even) {
		margin: 60px auto 0;
		@media only screen and (min-width: 800px) {
			flex-direction: row-reverse;
			margin-top: 80px;
		}
	}

	/* copy_wrapper "even" styles */
	&:nth-of-type(even) {
		.copy_wrapper {
			@media only screen and (max-width: 800px) {
				margin: 80px 0 36px;
			}

			@media only screen and (max-width: 600px) {
				margin: 36px 0 0;
			}

			margin-left: 0px;
			margin-right: 18px;

			@media only screen and (min-width: 960px) {
				margin-left: 0px;
				margin-right: 30px;
			}

			@media only screen and (min-width: 1010px) {
				margin-left: 0px;
				margin-right: 60px;
			}
		}
	}

	/* copy_wrapper "base" styles */
	.copy_wrapper {
		@media only screen and (max-width: 800px) {
			margin: 80px 0 36px;
		}

		@media only screen and (max-width: 600px) {
			margin: 36px 0 0;
		}

		flex: 0 0 45%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;

		margin-left: 18px;
		margin-right: 0px;

		@media only screen and (min-width: 960px) {
			margin-left: 30px;
			margin-right: 0px;
		}
		@media only screen and (min-width: 1010px) {
			margin-left: 60px;
			margin-right: 0px;
		}

		h3,
		p,
		div {
			width: 100%;
		}
	}
`

const ImgWrapper = styled.div`
	flex: 0 0 40%;
	height: 100%;
	width: 100%;
`

const StyledImg = styled(GatsbyImage)`
	display: block;
	width: 100%;
	height: 100%;
	max-width: 450px;
	min-height: 450px;
	margin-left: auto;
	margin-right: auto;
	${elevation[2]};

	.gatsby-image-outer-wrapper {
		width: 100%;
		height: 100%;
	}

	@media only screen and (min-width: 800px) {
		margin-left: auto;
		margin-right: 0;
	}
`
