import React from "react"
import { Hero } from "../../lib"

export default function HomeHeader() {
	return (
		<Hero
			line1="Distinctive interface"
			line2="Applications & web design"
			para="Developing systems & strategies to solve problems. Improving lifestyle with automation. Presenting users with clean, intelligent interfaces."
		/>
	)
}
